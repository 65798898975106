import { createLogger } from '@null-studios/universal-logger';
import React, { FunctionComponent, ReactNode, useEffect, useRef } from 'react';
import { useNavigation } from './provider_navigation/use_navigation';

const logger = createLogger('browser-router');

/*
 * Register marker with the navigation provider so that the user
 * can navigate seemlessly to markers from a URL hash.
 *
 * Example:
 *   Component:
 *       <Marker id="hash-marker">{children}</Marker>
 *
 *   URL:
 *       /some/url/with#hash-marker
 */
export const Marker: FunctionComponent<{ id: string; children: ReactNode }> = ({
  id,
  children,
}) => {
  const refElement = useRef<null | HTMLSpanElement>(null);
  const navigation = useNavigation();

  useEffect(() => {
    // Wait for marker to render.
    if (!refElement.current) {
      return;
    }

    const markerId = encodeURI(id);
    const hashId = navigation.hash.current.substring(1);

    // Do we even have the right hash?
    if (!hashId || markerId !== hashId) {
      return;
    }

    // Scroll to marker from location hash.
    logger.info(`window.scrollTo(${id})`);

    // TODO: is it this? markerToScrollTo.offsetTop - document.body.scrollTop
    refElement.current?.scrollIntoView({ behavior: 'smooth' });
  }, [id, navigation]);

  return <span ref={refElement}>{children}</span>;
};
