import { createContext, Dispatch, SetStateAction } from 'react';

export const Context = createContext<null | {
  // Allow certain API functionality (ex use_navigate) to execute *after*
  // this component is listening for events.
  isReady: boolean;
  setIsReady: Dispatch<SetStateAction<boolean>>;

  // Location origin.
  //   When `previous` is null the provider has just been loaded.
  origin: {
    previous: null | string;
    current: string;
  };
  setOrigin: (opts: { origin: string }) => void;
  // Location pathname.
  //   When `previous` is null the provider has just been loaded.
  pathname: {
    previous: null | string;
    current: string;
  };
  setPathname: (opts: { pathname: string }) => void;
  // Location hash.
  //   When `previous` is null the provider has just been loaded.
  hash: {
    previous: null | string;
    current: string;
  };
  setHash: (opts: { hash: string }) => void;
}>(null);