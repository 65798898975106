// WARNING: This file is auto generated by `./scripts/build_blog_posts`.
/* eslint-disable */

import asset_2023_01_12_hello_world from "./2023-01-12_hello_world.md";
import asset_duckypad from "./2023-01-22_mallardscript/duckypad.jpg";
import asset_duckyscript_example from "./2023-01-22_mallardscript/duckyscript_example.gif";
import asset_2023_01_22_mallardscript from "./2023-01-22_mallardscript.md";
import asset_usb_rubber_ducky from "./2023-01-22_mallardscript/usb_rubber_ducky.webp";
import asset_demo from "./2023-02-12_better_react_copy_component/demo.gif";
import asset_2023_02_12_better_react_copy_component from "./2023-02-12_better_react_copy_component.md";
import asset_cortona from "./2024-01-30_bookit/cortona.webp";
import asset_i3_configuration from "./2024-01-30_bookit/i3_configuration.png";
import asset_2024_01_30_bookit from "./2024-01-30_bookit.md";
import asset_search_ui from "./2024-01-30_bookit/search_ui.jpg";
import asset_tabs from "./2024-01-30_bookit/tabs.gif";
import asset_tray from "./2024-01-30_bookit/tray.jpg";
import asset_3d_printed_maze_0 from "./2024-01-30_mazes_mazes_mazes/3d_printed_maze_0.jpg";
import asset_3d_printed_maze_1 from "./2024-01-30_mazes_mazes_mazes/3d_printed_maze_1.jpg";
import asset_2024_01_30_mazes_mazes_mazes from "./2024-01-30_mazes_mazes_mazes.md";
import asset_2024_03_04_react_snake_25 from "./2024-03-04_react-snake-25.md";
import asset_snake from "./2024-03-04_react-snake-25/snake.gif";
import asset_2024_11_08_react_discord_presence from "./2024-11-08_react-discord-presence.md";
import asset_feed from "./feed.xml";

export const Assets: { [key: string]: string } = {
  [`./2023-01-12_hello_world.md`]: asset_2023_01_12_hello_world,
  [`./2023-01-22_mallardscript/duckypad.jpg`]: asset_duckypad,
  [`./2023-01-22_mallardscript/duckyscript_example.gif`]: asset_duckyscript_example,
  [`./2023-01-22_mallardscript.md`]: asset_2023_01_22_mallardscript,
  [`./2023-01-22_mallardscript/usb_rubber_ducky.webp`]: asset_usb_rubber_ducky,
  [`./2023-02-12_better_react_copy_component/demo.gif`]: asset_demo,
  [`./2023-02-12_better_react_copy_component.md`]: asset_2023_02_12_better_react_copy_component,
  [`./2024-01-30_bookit/cortona.webp`]: asset_cortona,
  [`./2024-01-30_bookit/i3_configuration.png`]: asset_i3_configuration,
  [`./2024-01-30_bookit.md`]: asset_2024_01_30_bookit,
  [`./2024-01-30_bookit/search_ui.jpg`]: asset_search_ui,
  [`./2024-01-30_bookit/tabs.gif`]: asset_tabs,
  [`./2024-01-30_bookit/tray.jpg`]: asset_tray,
  [`./2024-01-30_mazes_mazes_mazes/3d_printed_maze_0.jpg`]: asset_3d_printed_maze_0,
  [`./2024-01-30_mazes_mazes_mazes/3d_printed_maze_1.jpg`]: asset_3d_printed_maze_1,
  [`./2024-01-30_mazes_mazes_mazes.md`]: asset_2024_01_30_mazes_mazes_mazes,
  [`./2024-03-04_react-snake-25.md`]: asset_2024_03_04_react_snake_25,
  [`./2024-03-04_react-snake-25/snake.gif`]: asset_snake,
  [`./2024-11-08_react-discord-presence.md`]: asset_2024_11_08_react_discord_presence,
  [`./feed.xml`]: asset_feed,
};
