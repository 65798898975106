// WARNING: This file is auto generated by `./scripts/build_blog_posts`.
/* eslint-disable */

import { parseISO } from 'date-fns';
import { PostEntry } from '../../../api/query';
import post_frontmatter_2023_01_12_hello_world from './2023-01-12_hello_world.json';
import post_frontmatter_2023_01_22_mallardscript from './2023-01-22_mallardscript.json';
import post_frontmatter_2023_02_12_better_react_copy_component from './2023-02-12_better_react_copy_component.json';
import post_frontmatter_2024_01_30_bookit from './2024-01-30_bookit.json';
import post_frontmatter_2024_01_30_mazes_mazes_mazes from './2024-01-30_mazes_mazes_mazes.json';
import post_frontmatter_2024_03_04_react_snake_25 from './2024-03-04_react-snake-25.json';
import post_frontmatter_2024_11_08_react_discord_presence from './2024-11-08_react-discord-presence.json';
export const Posts: { [key: string]: PostEntry } = {
  '2023_01_12_hello_world': {
     id: "2023_01_12_hello_world",
     name: "2023-01-12 Hello World",
     frontmatter: {
       ...post_frontmatter_2023_01_12_hello_world,
       date: parseISO(post_frontmatter_2023_01_12_hello_world.date),
     },
     asset: "./2023-01-12_hello_world.md", 
  },
  '2023_01_22_mallardscript': {
     id: "2023_01_22_mallardscript",
     name: "2023-01-22 Mallardscript",
     frontmatter: {
       ...post_frontmatter_2023_01_22_mallardscript,
       date: parseISO(post_frontmatter_2023_01_22_mallardscript.date),
     },
     asset: "./2023-01-22_mallardscript.md", 
  },
  '2023_02_12_better_react_copy_component': {
     id: "2023_02_12_better_react_copy_component",
     name: "2023-02-12 Better React Copy Component",
     frontmatter: {
       ...post_frontmatter_2023_02_12_better_react_copy_component,
       date: parseISO(post_frontmatter_2023_02_12_better_react_copy_component.date),
     },
     asset: "./2023-02-12_better_react_copy_component.md", 
  },
  '2024_01_30_bookit': {
     id: "2024_01_30_bookit",
     name: "2024-01-30 Bookit",
     frontmatter: {
       ...post_frontmatter_2024_01_30_bookit,
       date: parseISO(post_frontmatter_2024_01_30_bookit.date),
     },
     asset: "./2024-01-30_bookit.md", 
  },
  '2024_01_30_mazes_mazes_mazes': {
     id: "2024_01_30_mazes_mazes_mazes",
     name: "2024-01-30 Mazes Mazes Mazes",
     frontmatter: {
       ...post_frontmatter_2024_01_30_mazes_mazes_mazes,
       date: parseISO(post_frontmatter_2024_01_30_mazes_mazes_mazes.date),
     },
     asset: "./2024-01-30_mazes_mazes_mazes.md", 
  },
  '2024_03_04_react_snake_25': {
     id: "2024_03_04_react_snake_25",
     name: "2024-03-04 React-Snake-25",
     frontmatter: {
       ...post_frontmatter_2024_03_04_react_snake_25,
       date: parseISO(post_frontmatter_2024_03_04_react_snake_25.date),
     },
     asset: "./2024-03-04_react-snake-25.md", 
  },
  '2024_11_08_react_discord_presence': {
     id: "2024_11_08_react_discord_presence",
     name: "2024-11-08 React-Discord-Presence",
     frontmatter: {
       ...post_frontmatter_2024_11_08_react_discord_presence,
       date: parseISO(post_frontmatter_2024_11_08_react_discord_presence.date),
     },
     asset: "./2024-11-08_react-discord-presence.md", 
  },
};

/* eslint-enable */
