import { useContext } from 'react';
import { Context } from './Context';

/*
 * Get configuration context.
 */
export const useConfiguration = () => {
  const navigation = useContext(Context);
  if (!navigation) {
    throw new Error(
      `useConfiguration must be used under a 'ProviderConfiguration' component.`,
    );
  }
  return navigation;
};
