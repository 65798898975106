import image_awesomenauts from './awesomenauts.png';
import image_bioshock from './bioshock.png';
import image_borderlands from './borderlands.png';
import image_brawlhalla from './brawlhalla.png';
import image_civ_6 from './civ_6.png';
import image_crysis from './crysis.png';
import image_drg from './drg.png';
import image_enter_the_gungeon from './enter_the_gungeon.png';
import image_factorio from './factorio.png';
import image_hollow_knight from './hollow_knight.png';
import image_last_of_us_1 from './last_of_us_1.png';
import image_last_of_us_2 from './last_of_us_2.png';
import image_left4dead from './left4dead.png';
import image_overwatch from './overwatch.png';
import image_portal from './portal.png';
import image_shadow_of_mordor from './shadow_of_mordor.png';
import image_shadow_of_the_tomb_raider from './shadow_of_the_tomb_raider.png';
import image_smite from './smite.png';
import image_smnc from './smnc.png';
import image_soma from './soma.png';
import image_space_pirate_trainer from './space_pirate_trainer.png';
import image_terraria from './terraria.png';
import image_tower_fall_ascension from './tower_fall_ascension.png';
import image_underale from './undertale.png';

// prettier-ignore
export const Games = [
  { rank: 5 , name: 'Last of Us 1'              , url: 'https://store.steampowered.com/app/1888930/The_Last_of_Us_Part_I/'                       , imageUrl: image_last_of_us_1 }              ,
  { rank: 5 , name: 'Last of Us 2'              , url: 'https://store.steampowered.com/app/1888930/The_Last_of_Us_Part_I/'                       , imageUrl: image_last_of_us_2 }              ,
  { rank: 5 , name: 'Shadow of the Tomb Raider' , url: 'https://store.steampowered.com/app/750920/Shadow_of_the_Tomb_Raider_Definitive_Edition/' , imageUrl: image_shadow_of_the_tomb_raider } ,
  { rank: 5 , name: 'Shadow of Mordor'          , url: 'https://store.steampowered.com/app/241930/Middleearth_Shadow_of_Mordor/'                 , imageUrl: image_shadow_of_mordor }          ,
  { rank: 5 , name: 'SOMA'                      , url: 'https://store.steampowered.com/app/282140/SOMA/'                                         , imageUrl: image_soma }                      ,
  { rank: 5 , name: 'Civilization 6'            , url: 'https://store.steampowered.com/app/289070/Sid_Meiers_Civilization_VI/'                   , imageUrl: image_civ_6 }                     ,
  { rank: 5 , name: 'Crysis'                    , url: 'https://store.steampowered.com/app/1715130/Crysis_Remastered/'                           , imageUrl: image_crysis }                    ,
  { rank: 5 , name: 'Overwatch'                 , url: 'https://store.steampowered.com/app/2357570/Overwatch_2/'                                 , imageUrl: image_overwatch }                 ,
  { rank: 5 , name: 'Factorio'                  , url: 'https://store.steampowered.com/app/427520/Factorio/'                                     , imageUrl: image_factorio }                  ,
  { rank: 5 , name: 'Portal'                    , url: 'https://store.steampowered.com/app/620/Portal_2/'                                        , imageUrl: image_portal }                    ,
  { rank: 5 , name: 'Smite'                     , url: 'https://store.steampowered.com/app/386360/SMITE/'                                        , imageUrl: image_smite }                     ,
  { rank: 5 , name: 'Super Monday Night Combat' , url: 'https://store.steampowered.com/app/63200/Monday_Night_Combat/'                           , imageUrl: image_smnc }                      ,
  { rank: 5 , name: 'Awesomenauts'              , url: 'https://store.steampowered.com/app/204300/Awesomenauts__the_2D_moba/'                    , imageUrl: image_awesomenauts }              ,
  { rank: 5 , name: 'Underale'                  , url: 'https://store.steampowered.com/app/391540/Undertale/'                                    , imageUrl: image_underale }                  ,
  { rank: 5 , name: 'Borderlands'               , url: 'https://store.steampowered.com/app/49520/Borderlands_2/'                                 , imageUrl: image_borderlands }               ,
  { rank: 5 , name: 'Deep Rock Galactic'        , url: 'https://store.steampowered.com/app/548430/Deep_Rock_Galactic/'                           , imageUrl: image_drg }                       ,
  { rank: 5 , name: 'Bioshock'                  , url: 'https://store.steampowered.com/app/7670/BioShock/'                                       , imageUrl: image_bioshock }                  ,
  { rank: 5 , name: 'Hollow Knight'             , url: 'https://store.steampowered.com/app/367520/Hollow_Knight/'                                , imageUrl: image_hollow_knight }             ,
  { rank: 5 , name: 'Brawlhalla'                , url: 'https://store.steampowered.com/app/291550/Brawlhalla/'                                   , imageUrl: image_brawlhalla }                ,
  { rank: 5 , name: 'Left4Dead'                 , url: 'https://store.steampowered.com/app/550/Left_4_Dead_2/'                                   , imageUrl: image_left4dead }                 ,
  { rank: 5 , name: 'Enter the Gungeon'         , url: 'https://store.steampowered.com/app/311690/Enter_the_Gungeon/'                            , imageUrl: image_enter_the_gungeon }         ,
  { rank: 5 , name: 'Terraria'                  , url: 'https://store.steampowered.com/app/105600/Terraria/'                                     , imageUrl: image_terraria }                  ,
  { rank: 5 , name: 'Space Pirate Trainer'      , url: 'https://store.steampowered.com/app/418650/Space_Pirate_Trainer/'                         , imageUrl: image_space_pirate_trainer }      ,
  { rank: 5 , name: 'Tower Fall Ascension'      , url: 'https://store.steampowered.com/app/251470/TowerFall_Ascension/'                          , imageUrl: image_tower_fall_ascension }      ,
];
