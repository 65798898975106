import { default as React, FunctionComponent, ReactNode } from 'react';
import { ConfigurationRouter } from '../types';
import { Context } from './Context';

/*
 * Provider for configuration context.
 */
export const ProviderConfiguration: FunctionComponent<{
  configuration: ConfigurationRouter;
  children: ReactNode;
}> = ({ configuration, children }) => {
  return (
    <Context.Provider
      value={{
        configuration,
      }}
    >
      {children}
    </Context.Provider>
  );
};
