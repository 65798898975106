import { default as ogSlugify } from 'slugify';

/*
 * Turn text into slugs.
 */
export const slugify = (input: string): string => {
  // Extensions.
  ogSlugify.extend({ '☢': 'radioactive' });

  return ogSlugify(input.toLowerCase());
};
