import React, { FunctionComponent } from 'react';
import { ProviderConfiguration } from './provider_configuration';
import { ProviderNavigation } from './provider_navigation/ProviderNavigation';
import { Redirect } from './Redirect';
import { Route } from './Route';
import { ConfigurationRouter } from './types';

/*
 * Page routing.
 */
export const Router: FunctionComponent<{
  configuration: ConfigurationRouter;
}> = ({ configuration }) => {
  return (
    <ProviderConfiguration configuration={configuration}>
      <ProviderNavigation>
        {configuration.routes.map((route, i) => {
          if (route.type === 'page') {
            return (
              <Route key={i} path={route.path}>
                <route.destination />
              </Route>
            );
          } else if (route.type === 'redirect') {
            return (
              <Route key={i} path={route.path}>
                <Redirect
                  isExternal={route.isExternal}
                  to={route.destination}
                />
              </Route>
            );
          } else {
            throw new Error(`Route type is invalid.`);
          }
        })}
      </ProviderNavigation>
    </ProviderConfiguration>
  );
};