import { createLogger } from '@null-studios/universal-logger';
import { useCallback } from 'react';
import { useNavigation } from './provider_navigation';

const logger = createLogger('browser-router');

/*
 * Provides functionality to navigate the user.
 */
export const useNavigate: () => (configuration: {
  isExternal?: boolean;
  to: string;
  replace?: boolean;
}) => void = () => {
  const navigation = useNavigation();

  return useCallback(
    (configuration: {
      isExternal?: boolean;
      to: string;
      replace?: boolean;
    }) => {
      // Navigation ready for window events?
      if (!navigation.isReady) {
        return;
      }

      // From || To
      const from = `${navigation.origin.current}${navigation.pathname.current}${navigation.hash.current}`;
      const to = `${navigation.origin.current}${configuration.to}`;
      logger.info(
        `Navigate: from='${from}' to='${to}' isExternal=${configuration.isExternal}.`,
      );

      // Already here?
      if (from === to) {
        logger.info(`Already on this route - skipping...`);
        return;
      }

      // External route?
      if (configuration.isExternal) {
        window.location.href = to;
      } else {
        // Replace current state?
        // Allows for redirects to be excluded from history.
        if (
          configuration.replace ||
          // If a to hash was set replace history.
          (typeof configuration.replace === 'undefined' &&
            new URL(to).hash.length > 1)
        ) {
          window.history.replaceState({}, '', to);
        } else {
          window.history.pushState({}, '', to);
        }

        // Scroll to Top.
        // Except if we've just set a hash - this will interfere with Markers.
        // NOTE: Browsers try to keep the scroll position for the same document (ie SPA).
        const urlFrom = new URL(from);
        const urlTo = new URL(to);
        if (urlFrom.pathname !== urlTo.pathname) {
          window.scrollTo(0, 0);
        }

        // Indicate to the window that the history changed manually.
        window.dispatchEvent(new PopStateEvent('popstate'));
      }
    },
    [navigation],
  );
};